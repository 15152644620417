import React , { useEffect ,useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useRouteMatch, useHistory } from 'react-router-dom';
import MainLogo from "./MainLogo";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Link as RouterLink } from "react-router-dom";
import { userRoutes } from "../routes/Users/constants";
import MenuCart from "./MenuCart";
import SearchBar from "./SearchBar";
import { Typography } from "@material-ui/core";
import LogoutButton from "./HeaderHamburger";
import TranslationFlag from "./TranslationFlag";
import { useQuery } from "@apollo/react-hooks";
import { CUSTOMER_BY_CODE } from "../queries-mutations/customers";
import { connect } from "react-redux";
import { Translate } from "react-auto-translate";

function UsersHeaderMenu(props) {
  console.log(props,"props")
  const [duiten, setDuiten] = useState(props.userInformation.duiten);

  console.log("duiten",duiten)
  const classes = headerMenu();
  let match = useRouteMatch();
  const history = useHistory();
  const { loading, error, data, refetch } = useQuery(CUSTOMER_BY_CODE, {
    variables: { code: props.userInformation.code }
  });

  useEffect(() => {
    const calculateDuiten = () => {
      const totalCartPrice = props.cart.reduce((acc, item) => acc + item.price, 0);

      console.log("totalCartPrice",totalCartPrice)
      console.log("props.userInformation.duiten",props.userInformation.duiten)
      setDuiten(props.userInformation.duiten - totalCartPrice);

      //console.log("setDuiten(props.userInformation.duiten - totalCartPrice)",setDuiten(props.userInformation.duiten - totalCartPrice))
    };

    calculateDuiten();
  }, [props.cart, props.userInformation.duiten]);

  useEffect(() => {
    // const unlisten = history.listen( async () => {
    let path = `${match.path}/bestelling-geslaagd`; 
    const fetchData = async () => {
      if (history.location.pathname === path) {
        console.log("change route",props);
        await refetch();

        if (data) {
          console.log("data ==>" , data)
          // setDuiten(data.customerByCode.duiten);
        }
      }
    };
  
    fetchData();
    // });

    const unlisten = history.listen(  async () => {
      if (history.location.pathname === path) {
        console.log("change route1",props);
        await refetch();
        if (data) {
          console.log("data1 ==>" , data)
          // setDuiten(data.customerByCode.duiten);
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Grid
      container
      style={{
        "@media (max-width: 898px)": {
          justifyContent: "space-between"
        },
        marginTop: '12px',
        overflowX: 'hidden'
      }}
    >
      <Grid item xs={2} md={2} component={RouterLink} to={userRoutes.SHOP} 
      style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
        <MainLogo
          style={{
            height: 55,
            "@media (min-width: 769px)": {
              position: "absolute"
            }
          }}
        />
      </Grid>

      <Grid item xs={6} sm={6} style={{margin:'auto 0px'}} className={classes.hideOnDesktop}>
        <Typography style={{float:'left'}}>
          <Translate>Welkom {props.userInformation.firstName +' '+props.userInformation.lastName}!</Translate>
        </Typography>
        <Typography style={{float:'left'}}>
          <Translate>Duiten te besteden:</Translate> <Translate>{duiten}</Translate>
        </Typography>
      </Grid>
      
      <Grid item xs={4} sm={4} className={classes.hideOnDesktop}>
        <Grid container>
          <Grid item xs={4}>
            <MenuCart />
          </Grid>
          <Grid item xs={4} style={{margin:'auto', height: "25px"}}>
            <TranslationFlag />
          </Grid>
          <Grid item xs={4}>
            <LogoutButton />
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={3} className={classes.hideOnMobile}>
        <SearchBar />
      </Grid>

      <Grid item xs={4} sm={4} style={{margin:'auto 0px', textAlign: 'end'}} className={classes.hideOnMobile}>
        <Typography>
          <Translate>
            {`Welkom ${props.userInformation.firstName} ${props.userInformation.lastName}! je hebt nog: ${duiten} duiten te besteden`}
          </Translate>
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.hideOnDesktop}>
        <Paper component="form" className={classes.searchContainer}>
          <InputBase
            className={classes.searchInput}
            placeholder="Zoeken"
            disableElevation
          />
          <IconButton
            type="submit"
            className={classes.searchIcon}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={2} md={2} className={classes.hideOnMobile} >
        <Grid container style={{justifyContent: 'space-evenly', alignItems: 'center'}}>
          <Grid>
            <MenuCart />
          </Grid>
          <Grid>
            <TranslationFlag />
          </Grid>
          <Grid>
            <LogoutButton />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { cart } = state;
  const { userInformation } = state;
  return { cart, userInformation, ownProps };
};

export default connect(mapStateToProps)(UsersHeaderMenu);

const headerMenu = makeStyles(theme => ({
  searchContainer: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "95%",
    margin: "5px auto",
    border: 0,
    backgroundColor: "#f6f6f6",
    borderRadius: 3,
  },
  searchInput: {
    width: "90%",
    boxShadow: "none"
  },
  searchIcon: {},
  genericIcon: {
    fontSize: 30
  },
  hideOnMobile: {
    "@media (max-width: 898px)": {
      display: "none"
    }
  },
  hideOnDesktop: {
    "@media (min-width: 898px)": {
      display: "none"
    }
  }
}));

