import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { categories } from "../queries-mutations/products";
import Grid from "@material-ui/core/Grid";
import SingleCategorySmall from "./SingleCategorySmall";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Translate } from "react-auto-translate";

const styles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.main,
    textAlign: "left",
    marginLeft: 20,
    fontSize: 30
  },
  container: { marginTop: 30 }
}));

export default function MainCategoryOverview() {
  const classes = styles();

  const { loading, error, data } = useQuery(categories);

  // data.categories = data.categories.filter(category => {
  //   return category.published;
  // })

  if (data) {
    return (
      <Grid container className={classes.container}>
        <Grid xs={12}>
          <h2 className={classes.text}>
            <Translate>Kramen</Translate>
          </h2>
        </Grid>
        {data.categories.filter(category => {
           return category.published;
          }).map(category => (
          <SingleCategorySmall
            alt={category.name}
            newImg={category.image && category.image.new}
            img={category.image && category.image.location}
            id={category.id}
          />
        ))}
      </Grid>
    );
  } else if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } else if (error) {
    return (
      <div>
        <h2>
          <Translate>Er ging iets fout</Translate>
        </h2>
      </div>
    );
  }
}
