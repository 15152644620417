import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = makeStyles(theme => ({
  image: {
    maxHeight: 375,
    objectFit: "fill",
    width: "100%",
    objectPosition: "top"
  },
  overlay: {
    position: "relative"
  },
  container: {
    position: "relative",
    maxHeight: 500
  },
  textContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  },
  text: {
    color: "black",
    fontWeight: 300
  },
  centeredText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

export default function FullWidthImage(props) {
  const classes = styles();
  return (
    <div className={classes.container}>
      <img alt={props.alt} src={props.src} className={classes.image} />
      <Grid
        container
        className={
          props.overlay
            ? `${classes.textContainer} ${classes.overlay}`
            : classes.textContainer
        }
      >
        {/* <Grid xs={3} /> */}
        <Grid xs={6} className={classes.centeredText}>
          <h1 className={classes.text}>{props.text}</h1>
        </Grid>
      </Grid>
    </div>
  );
}
