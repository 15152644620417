import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  previous: {
    textAlign: "Left",
    padding: 4,
    border: "1px solid rgba(0, 0, 0, 0.5)",
    width: 70,
    fontSize: 14,
    borderRadius: 4,
    display: "flex",
    alignItems: "center"

  },
  backIcon: {
    fontSize: 14,
  },
}));

export default function BackButton(props) {
  const classes = styles();

  const history = useHistory();

  const previous = () => {
    history.goBack();
  };

  return (
    <Typography className={classes.previous} onClick={previous}>
      <ArrowBackIosIcon className={classes.backIcon} />
      Ga terug
    </Typography>
  );
}
