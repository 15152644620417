import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { CATEGORY } from "../../queries-mutations/products";
import parse from "html-react-parser";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SingleProductSmall from "../../components/SingleProductSmall";
import { Translate } from "react-auto-translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import BackButton from "../../components/BackButton";
import categoryBanner from "../../assets/1.png";
import FullWidthImage from "../../components/FullWidthImage";

const styles = makeStyles(theme => ({
  imageContainer: {
    width: "100%",
    margin: "10px 0px"
  },
  text: {
    textAlign: "left",
    color: theme.palette.text.main
  }
}));

function SingleCategory(props) {
  let { id } = useParams();
  const classes = styles();

  const { loading, error, data } = useQuery(CATEGORY, {
    variables: { id: id }
  });
  if(data){
  data.category.products = data.category.products.slice().sort((a, b) => a.order - b.order);
  }
  console.log(data);

  if (data) {
    return (
      <Grid container justify="center">
        <Grid xs={12} className={classes.imageContainer}>
          <FullWidthImage src={categoryBanner} overlay={false} />
        </Grid>
        <Grid xs={0} md={1} xl={2} />
        <Grid xs={12} md={11} xl={10} className={classes.previousContainer}>
          <BackButton />
        </Grid>
        <Grid item xs={12} md={10} xl={8} className={classes.text}>
          <h1>
            <Translate>{data.category.name}</Translate>
          </h1>
          {parse(data.category.description)}
        </Grid>
        <Grid item xs={12} md={10} xl={8} className={classes.text}>
          <Grid container justify-content="space-between">
            {data.category.products.map(
              product =>
                product.published &&
                product.stockAmount > 0 &&
                product.price <= props.userInformation.duiten && (
                  <SingleProductSmall
                    newImg={product.images[0] ? product.images[0].new : false}
                    img={product.images[0] ? product.images[0].location : null}
                    name={product.name}
                    price={product.price}
                    id={product.id}
                  />
                )
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  } else if (error) {
    return (
      <div>
        <h2>
          <Translate>Er ging iets mis, probeer het later opnieuw</Translate>
        </h2>
      </div>
    );
  } else {
    return (
      <div>
        <h1>
          <Translate>Helaas konden we deze categorie niet vinden!</Translate>
        </h1>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { userInformation } = state;
  return { userInformation };
};

export default connect(mapStateToProps)(SingleCategory);
