import React, { useState , useEffect, useRef } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import SingleProductCart from "../../components/SingleProductCart";
import Button from "@material-ui/core/Button";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CUSTOMER_BY_CODE,
  PLACE_ORDER,
  ORDERS_BY_CUSTOMER_CODE
} from "../../queries-mutations/customers";
import { removeFromCart ,updateToCart} from "../../actions";
import Grid from "@material-ui/core/Grid";
import DuitenIcon from "../../components/DuitenIcon";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { userRoutes } from "./constants";
import CartSuggestions from "../../components/CartSuggestions";
import BackButton from "../../components/BackButton";
import ConfirmationRequiredButton from "../../components/ConfirmationRequiredButton";
import { Translate } from "react-auto-translate";
import { products, PRODUCTS_SHOP, ADMIN_OVERVIEW_PRODUCTS } from "../../queries-mutations/products";
import { setDuiten } from "../../actions";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const styles = makeStyles(theme => ({
  container: {
    padding: 25
  },
  rightText: {
    textAlign: "Left"
  },
  rightItem: {
    width: "100%",
    marginBottom: 15
  },
  paper: {
    padding: 12,
    width: "100%"
  },
  previousContainer: {
    paddingLeft: 25
  },
  headerTitle: {
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center"
  },
  warning: {
    color: "red"
  }
}));

function Cart(props) {
  console.log(props, "props cart")
  let history = useHistory();
  const isMounted = useRef(true);

  const [initiatedOrder, setInitiatedOrder] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [stockLength, setStockLength] = useState(false);
  const [addCarts, setaddCarts] = useState([]);
  const initiateOrder = () => {
    setInitiatedOrder(true);
    console.log("initial order")
  };

  const cancelOrder = () => {
    setInitiatedOrder(false);
  };

  useEffect(() => {
    console.log('Component mounted!');
    let output = props.cart.map((item) => ({
      ...item,
      stock: false,
    }));
    setaddCarts(output)
    console.log(output )
    return () => {
      isMounted.current = false;
    };
  }, []);

  const confirmOrder = () => {
    setbtnDisabled(true);

setTimeout(() => {
  handleBestellen();
}, 1000);
   
  };

  const classes = styles();

  const [bestellingStatus, setBestellingStatus] = useState(<div />);

  const [bezig, setBezig] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  const { loading, error, data, refetch } = useQuery(CUSTOMER_BY_CODE, {
    variables: { code: props.userInformation.code }
  });

  const { loading: OrderLoading, error: OrderError, data: OrderData, refetch: orderRefetch } = useQuery(ORDERS_BY_CUSTOMER_CODE, {
    variables: { code: props.userInformation.code }
  });

  const {
    data: data_pro,
    error: error_pro,
    loading: loading_pro,
    refetch: refetch_pro
  } = useQuery(ADMIN_OVERVIEW_PRODUCTS, {
    returnPartialData: false, errorPolicy: "ignore" 
  },{ errorPolicy: "all" });

  const [placeOrder] = useMutation(PLACE_ORDER);

  const search = (nameKey, myArray) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].id === nameKey) {
        return myArray[i];
      }
    }
  };

  const handleClick = (names) => {
    setAlertMessage(names);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setStockLength(false);
  };


  // const handleBestellen = () => {
  //   setbtnDisabled(true);
  //   setBezig(true);
  //   refetch().then(() => {
  //     refetch_pro().then(() => {
  //       if (data) {
  //         console.log("con")
  //         let duiten = data.customerByCode.duiten;
  //         let totaal = props.cart
  //           .map(o => o.price)
  //           .reduce((a, c) => {
  //             return a + c;
  //           });
  //         if (totaal > duiten) {
  //           setBestellingStatus(
  //             <Typography className={classes.warning}>
  //               <Translate>Je hebt niet genoeg duiten!</Translate>
  //             </Typography>
  //           );
  //         } else {
  //           let products = [];
  //           let out = false;
  //           props.cart.map(product => {
  //             products.push(product.id);
  //           });
  //           products.map(productID => {
  //             let found = [];
  //             products.map(counter => {
  //               if (counter === productID) {
  //                 found.push(counter);
  //               }
  //             });
  //             let checkProduct = search(productID, data_pro.products);
  //             if (checkProduct.stockAmount < found.length) {
  //               props.cart.map((product, index) => {
  //                 product.id === productID &&
  //                   props.dispatch(removeFromCart(index));
  //               });
  //               out = true;
  //             }
  //           });
  //           if (out) {
  //             setBestellingStatus(
  //               <Typography className={classes.warning}>
  //                 <Translate>
  //                   Een van de producten die je hebt gekozen is helaas op, kies
  //                   alsjeblieft iets anders
  //                 </Translate>
  //               </Typography>
  //             );
  //           } else {
  //             console.log("place order")
  //             placeOrder({
  //               variables: {
  //                 customer: data.customerByCode.id,
  //                 products: products
  //               }
  //             }).then(() => {
  //               props.cart.map((product, index) => {
  //                 console.log("remove card")
  //                 props.dispatch(removeFromCart(index));
  //               });
  //               history.push(userRoutes.SUCCESS);
  //             });
  //           }
  //         }
  //       } else if (loading) {
  //         return (
  //           <div>
  //             <CircularProgress color="secondary" />
  //           </div>
  //         );
  //       } else if (error) {
  //         return (
  //           <div>
  //             <h2>
  //               <Translate>
  //                 Er ging iets fout, probeer het later opnieuw
  //               </Translate>
  //             </h2>
  //           </div>
  //         );
  //       }
  //     });
  //   });
  //   setBezig(false);
  // };

  function removeDuplicates(array) {
    const uniqueIds = new Set();
    return array.filter((item) => {
      const isDuplicate = uniqueIds.has(item.id);
      uniqueIds.add(item.id);
      return !isDuplicate;
    });
  }

  const handleBestellen = async () => {
    console.log("handleBestellen",new Date().toISOString());
    setBezig(true);
   
    if(data){
      console.log("data",new Date().toISOString());
    }
  
      if (data) {
        console.log("data" , data)
        let duiten = data.customerByCode.duiten;
        let totaal = props.cart
          .map(o => o.price)
          .reduce((a, c) => {
            return a + c;
          });
        if (totaal > duiten) {
          console.log("true")
          setBestellingStatus(
            <Typography className={classes.warning}>
              <Translate>Je hebt niet genoeg duiten!</Translate>
            </Typography>
          );
        } else {
          
         
          let products = [];
          let out = false;
          props.cart.map(product => {
            products.push(product.id);
          });
         
          products.map(productID => {
            let found = [];
            products.map(counter => {
              if (counter === productID) {
                found.push(counter);
              }
            });
            console.log("data_pro", data_pro)
            // let checkProduct = search(productID, data_pro.products);
            // if (checkProduct.stockAmount < found.length) {
              let checkProduct = data_pro && data_pro.products ? search(productID, data_pro.products) : null;

            //   if (checkProduct && checkProduct.stockAmount < found.length) {
            //   props.cart.map((product, index) => {
            //     product.id === productID &&
            //       // props.dispatch(removeFromCart(index));
            //       props.removeCard(0);
            //   });
            //   out = true;
            // }
          });
          console.log("else",out )
          if (out) {
            setBestellingStatus(
              <Typography className={classes.warning}>
                <Translate>
                  Een van de producten die je hebt gekozen is helaas op, kies
                  alsjeblieft iets anders
                </Translate>
              </Typography>
            );
          } else {
            console.log("placeOrder ",data.customerByCode.id ,products )
            console.log(userRoutes.SUCCESS)
           var resultPlaceOrder =  await placeOrder({
             variables: {
                customer: data.customerByCode.id,
                products: products
              }
            });
            console.log("resultPlaceOrder", resultPlaceOrder.data.placeOrder.success);
            if( resultPlaceOrder.data.placeOrder.success  ){
        
            props.cart.forEach((product, index) => {
              console.log(index)
           
              props.removeCard(0);

            });

            props.updateDuiten(0)
            console.log("card remove", props.cart);
            if (isMounted.current) {
              console.log("isMounted end",isMounted.current)
          
              await orderRefetch();

              if(OrderData){
                console.log("OrderData",OrderData);
              }

              history.push(userRoutes.SUCCESS);
            }else{
              console.log("isMounted process")
            }

          }else{  // out of stock
            console.log("false")
             let outOfStock = resultPlaceOrder.data.placeOrder.products
             const uniqueOutOfStock  = removeDuplicates(outOfStock);
             const inStock = uniqueOutOfStock.filter((item) => item.stock > 0);
             const isStock = outOfStock.filter((item) => item.stock == 0);
             console.log(outOfStock, " <=== outOfStock")
             setbtnDisabled(false);
            setBezig(false);
            let namelist = uniqueOutOfStock.map(item => '${item.name},');

            console.log(namelist.join(', '));

            let output = props.cart.map((item, index) => {
              console.log("map index",index)
              if (isStock.some((v) => v.id === item.id)) {
              
                props.updateCart(item.id,index)
              }
              return item; // No match, return the original item
            });

            console.log("product.card",props.cart)
            setaddCarts(output)
            
            if (inStock.length === 0) {
              handleClick(namelist)
              setOpen(true);
            } else {

              const stocklist = inStock.map((product) => {
                return `${product.name} product available stock is ${product.stock},`;
              });
              console.log('The "inStock" array has values:', stocklist);
              handleClick(stocklist)
              setStockLength(true);
            }
          }
           
          }
        }
      } else if (loading) {
        console.log("loading")
        return (
          <div>
            <CircularProgress color="secondary" />
          </div>
        );
      } else if (error) {
        return (
          <div>
            <h2>
              <Translate>
                Er ging iets fout, probeer het later opnieuw
              </Translate>
            </h2>
          </div>
        );
      }
  
    setBezig(false);
  };

  if (props.cart.length > 0) {
    return (
      <div>
       <Snackbar  open={open}  onClose={handleClose}>
        <Alert   style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }} onClose={handleClose} severity="error">
       
      
        <Translate> U kunt helaas geen order plaatsen want, ons artikel{' '}
      <strong>{alertMessage}</strong> is uit onze voorraad, kies een ander artikel. </Translate>
        </Alert>
        
      </Snackbar> 

      <Snackbar  open={stockLength}  onClose={handleClose}>
        <Alert   style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }} onClose={handleClose} severity="error">
       
      
        <Translate> U kunt helaas geen order plaatsen want, ons artikel{' '}
      <strong>{alertMessage}</strong>  kies een ander artikel. </Translate>
        </Alert>
      </Snackbar> 
        
      <Grid container>
        <Grid xs={12} md={11} xl={10} className={classes.previousContainer}>
          <BackButton />
        </Grid>
        <Grid xs={12} md={11} xl={10} className={classes.headerTitle}>
          <h2 style={{margin: '5px'}}>
            <Translate>Maak je bestelling compleet</Translate>
          </h2>
        </Grid>
        <Grid item xs={12} md={6} className={classes.container}>
          <Paper elevation={2} className={classes.paper} style={{height: '400px', overflowY: 'auto'}}>
            <Typography className={classes.rightText}>
              <h2>
                <Translate>Door jou gekozen items</Translate>
              </h2>
            </Typography>
            {props.cart.map((product, index) => (
              <SingleProductCart
                index={index}
                name={product.name}
                id={product.id}
                img={product.img}
                price={product.price}
                stock={product.stock}
              />
            ))}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className={classes.container}>
          <Paper elevation={2} className={classes.paper} style={{height: '400px'}}>
            <Grid container justify-content="flex-start" alignItems="flex-start">
              <Grid item xs={12}>
                <Typography className={classes.rightText}>
                  <h2>
                    <Translate>Bestellen</Translate>
                  </h2>
                </Typography>
                <Typography className={classes.rightText}>
                  <p>
                    <Translate>
                      Je bestelling wordt bij je werkgever geleverd.
                    </Translate>
                  </p>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.rightText}>
                  <Translate>Bedrijf:</Translate>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.rightText}>
                  {data ? data.customerByCode.company.name : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.rightText}>
                  <Translate>Naam:</Translate>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.rightText}>
                  {data ? data.customerByCode.firstName : ""}{" "}
                  {data &&
                  data.customerByCode.insertion &&
                  data.customerByCode.insertion.length > 0
                    ? `${data.customerByCode.insertion} `
                    : ""}
                  {data ? data.customerByCode.lastName : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.rightText}>
                  <Translate>Email:</Translate>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.rightText}>
                  {data ? data.customerByCode.email : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.rightText}>Code:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.rightText}>
                  {data ? data.customerByCode.code : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.rightText}>
                  <Translate>Te besteden duiten:</Translate>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.rightText}>
                  {data ? data.customerByCode.duiten : "0"}{" "}
                  <DuitenIcon viewbox="0 0 24 12" />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.rightText}>
                  <Translate>Totaal in winkelmandje:</Translate>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.rightText}>
                  {props.cart
                    .map(o => o.price)
                    .reduce((a, c) => {
                      return a + c;
                    })}{" "}
                  <DuitenIcon viewbox="0 0 24 12" />
                </Typography>
              </Grid>
              <Grid items xs={12}>
                {bestellingStatus}
              </Grid>
              <Grid item xs={12}>
                {props.cart
                  .map(o => o.price)
                  .reduce((a, c) => {
                    return a + c;
                  }) > (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                  <Typography className={classes.warning}>
                    <Translate>
                      Je hebt onvoldoende duiten voor deze bestelling
                    </Translate>
                  </Typography>
                ) : props.cart
                    .map(o => o.price)
                    .reduce((a, c) => {
                      return a + c;
                    }) !== (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                  <Typography className={classes.warning}>
                    <Translate>
                      Je hebt nog niet al je duiten opgemaakt
                    </Translate>
                  </Typography>
                ) : (
                  <Typography>Bevestig je bestelling</Typography>
                )}
                {props.cart
                  .map(o => o.price)
                  .reduce((a, c) => {
                    return a + c;
                  }) > (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={true}
                  >
                    <Translate>Bestellen</Translate>
                  </Button>
                ) : props.cart
                    .map(o => o.price)
                    .reduce((a, c) => {
                      return a + c;
                    }) !== (data ? parseInt(data.customerByCode.duiten) : 0) ? (
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={true}
                  >
                    <Translate>Bestellen</Translate>
                  </Button>
                ) : (
                  <ConfirmationRequiredButton
                    disabled={btnDisabled}
                    initiated={initiatedOrder}
                    cancel={cancelOrder}
                    initiate={initiateOrder}
                    confirm={confirmOrder}
                    confirmationMessage={
                      "Weet je zeker dat je deze Bestelling wil plaatsen?"
                    }
                    cancelMessage={"Annuleren"}
                    confirmMessage={"Bestellen"}
                    initialMessage={"Bestellen"}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {data &&
          parseInt(data.customerByCode.duiten) -
            props.cart
              .map(o => o.price)
              .reduce((a, c) => {
                return a + c;
              }) >
            0 &&
          parseInt(data.customerByCode.duiten) -
            props.cart
              .map(o => o.price)
              .reduce((a, c) => {
                return a + c;
              }) <
            5 && (
            <Grid item xs={12} className={classes.container}>
              <Paper elevation={2} className={classes.paper}>
                <>
                <CartSuggestions userInformation={props.userInformation}/>
                </>
              </Paper>
            </Grid>
          )}
      </Grid>
        
      </div>
     
    );
  } else {
    return (
      <Paper>
        <Grid xs={12} md={11} xl={10} className={classes.previousContainer}>
          <BackButton />
        </Grid>
        <h1>
          <Translate>Er zit niks in je winkelwagen</Translate>
        </h1>
      </Paper>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  const { cart } = state;
  const { userInformation } = state;
  return { cart, userInformation, ownProps };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDuiten: (val) => {
      dispatch(setDuiten(val));
    },
    removeCard: (item) => {
      dispatch(removeFromCart(item));
     
    },
    updateCart: (id,index) => {
      console.log("index",index)
      dispatch(updateToCart(id,index));     
    }
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Cart);
